export const languages = [
  {
    id: 63,
    name: "JavaScript (Node.js 12.14.0)",
    label: "JavaScript (Node.js 12.14.0)",
    value: "javascript",
  },
  {
    id: 71,
    name: "Python (3.8.1)",
    label: "Python (3.8.1)",
    value: "python",
  },
];

// {
//     "id": 45,
//     "name": "Assembly (NASM 2.14.02)",
//     "is_archived": false
//   },
//   {
//     "id": 2,
//     "name": "Bash (4.0)",
//     "is_archived": true
//   },
//   {
//     "id": 1,
//     "name": "Bash (4.4)",
//     "is_archived": true
//   },
//   {
//     "id": 46,
//     "name": "Bash (5.0.0)",
//     "is_archived": false
//   },
//   {
//     "id": 3,
//     "name": "Basic (fbc 1.05.0)",
//     "is_archived": true
//   },
//   {
//     "id": 47,
//     "name": "Basic (FBC 1.07.1)",
//     "is_archived": false
//   },
//   {
//     "id": 15,
//     "name": "C++ (g++ 4.8.5)",
//     "is_archived": true
//   },
//   {
//     "id": 14,
//     "name": "C++ (g++ 4.9.4)",
//     "is_archived": true
//   },
//   {
//     "id": 13,
//     "name": "C++ (g++ 5.4.0)",
//     "is_archived": true
//   },
//   {
//     "id": 12,
//     "name": "C++ (g++ 6.3.0)",
//     "is_archived": true
//   },
//   {
//     "id": 11,
//     "name": "C++ (g++ 6.4.0)",
//     "is_archived": true
//   },
//   {
//     "id": 10,
//     "name": "C++ (g++ 7.2.0)",
//     "is_archived": true
//   },
//   {
//     "id": 9,
//     "name": "C (gcc 4.8.5)",
//     "is_archived": true
//   },
//   {
//     "id": 8,
//     "name": "C (gcc 4.9.4)",
//     "is_archived": true
//   },
//   {
//     "id": 7,
//     "name": "C (gcc 5.4.0)",
//     "is_archived": true
//   },
//   {
//     "id": 6,
//     "name": "C (gcc 6.3.0)",
//     "is_archived": true
//   },
//   {
//     "id": 5,
//     "name": "C (gcc 6.4.0)",
//     "is_archived": true
//   },
//   {
//     "id": 4,
//     "name": "C (gcc 7.2.0)",
//     "is_archived": true
//   },
//   {
//     "id": 48,
//     "name": "C (GCC 7.4.0)",
//     "is_archived": false
//   },
//   {
//     "id": 52,
//     "name": "C++ (GCC 7.4.0)",
//     "is_archived": false
//   },
//   {
//     "id": 49,
//     "name": "C (GCC 8.3.0)",
//     "is_archived": false
//   },
//   {
//     "id": 53,
//     "name": "C++ (GCC 8.3.0)",
//     "is_archived": false
//   },
//   {
//     "id": 50,
//     "name": "C (GCC 9.2.0)",
//     "is_archived": false
//   },
//   {
//     "id": 54,
//     "name": "C++ (GCC 9.2.0)",
//     "is_archived": false
//   },
//   {
//     "id": 18,
//     "name": "Clojure (1.8.0)",
//     "is_archived": true
//   },
//   {
//     "id": 17,
//     "name": "C# (mono 5.2.0.224)",
//     "is_archived": true
//   },
//   {
//     "id": 16,
//     "name": "C# (mono 5.4.0.167)",
//     "is_archived": true
//   },
//   {
//     "id": 51,
//     "name": "C# (Mono 6.6.0.161)",
//     "is_archived": false
//   },
//   {
//     "id": 55,
//     "name": "Common Lisp (SBCL 2.0.0)",
//     "is_archived": false
//   },
//   {
//     "id": 19,
//     "name": "Crystal (0.23.1)",
//     "is_archived": true
//   },
//   {
//     "id": 56,
//     "name": "D (DMD 2.089.1)",
//     "is_archived": false
//   },
//   {
//     "id": 20,
//     "name": "Elixir (1.5.1)",
//     "is_archived": true
//   },
//   {
//     "id": 57,
//     "name": "Elixir (1.9.4)",
//     "is_archived": false
//   },
//   {
//     "id": 21,
//     "name": "Erlang (OTP 20.0)",
//     "is_archived": true
//   },
//   {
//     "id": 58,
//     "name": "Erlang (OTP 22.2)",
//     "is_archived": false
//   },
//   {
//     "id": 44,
//     "name": "Executable",
//     "is_archived": false
//   },
//   {
//     "id": 59,
//     "name": "Fortran (GFortran 9.2.0)",
//     "is_archived": false
//   },
//   {
//     "id": 60,
//     "name": "Go (1.13.5)",
//     "is_archived": false
//   },
//   {
//     "id": 22,
//     "name": "Go (1.9)",
//     "is_archived": true
//   },
//   {
//     "id": 24,
//     "name": "Haskell (ghc 8.0.2)",
//     "is_archived": true
//   },
//   {
//     "id": 23,
//     "name": "Haskell (ghc 8.2.1)",
//     "is_archived": true
//   },
//   {
//     "id": 61,
//     "name": "Haskell (GHC 8.8.1)",
//     "is_archived": false
//   },
//   {
//     "id": 25,
//     "name": "Insect (5.0.0)",
//     "is_archived": true
//   },
//   {
//     "id": 62,
//     "name": "Java (OpenJDK 13.0.1)",
//     "is_archived": false
//   },
//   {
//     "id": 28,
//     "name": "Java (OpenJDK 7)",
//     "is_archived": true
//   },
//   {
//     "id": 27,
//     "name": "Java (OpenJDK 8)",
//     "is_archived": true
//   },
//   {
//     "id": 26,
//     "name": "Java (OpenJDK 9 with Eclipse OpenJ9)",
//     "is_archived": true
//   },
//   {
//     "id": 63,
//     "name": "JavaScript (Node.js 12.14.0)",
//     "is_archived": false
//   },
//   {
//     "id": 30,
//     "name": "JavaScript (nodejs 7.10.1)",
//     "is_archived": true
//   },
//   {
//     "id": 29,
//     "name": "JavaScript (nodejs 8.5.0)",
//     "is_archived": true
//   },
//   {
//     "id": 64,
//     "name": "Lua (5.3.5)",
//     "is_archived": false
//   },
//   {
//     "id": 31,
//     "name": "OCaml (4.05.0)",
//     "is_archived": true
//   },
//   {
//     "id": 65,
//     "name": "OCaml (4.09.0)",
//     "is_archived": false
//   },
//   {
//     "id": 32,
//     "name": "Octave (4.2.0)",
//     "is_archived": true
//   },
//   {
//     "id": 66,
//     "name": "Octave (5.1.0)",
//     "is_archived": false
//   },
//   {
//     "id": 33,
//     "name": "Pascal (fpc 3.0.0)",
//     "is_archived": true
//   },
//   {
//     "id": 67,
//     "name": "Pascal (FPC 3.0.4)",
//     "is_archived": false
//   },
//   {
//     "id": 68,
//     "name": "PHP (7.4.1)",
//     "is_archived": false
//   },
//   {
//     "id": 43,
//     "name": "Plain Text",
//     "is_archived": false
//   },
//   {
//     "id": 69,
//     "name": "Prolog (GNU Prolog 1.4.5)",
//     "is_archived": false
//   },
//   {
//     "id": 37,
//     "name": "Python (2.6.9)",
//     "is_archived": true
//   },
//   {
//     "id": 70,
//     "name": "Python (2.7.17)",
//     "is_archived": false
//   },
//   {
//     "id": 36,
//     "name": "Python (2.7.9)",
//     "is_archived": true
//   },
//   {
//     "id": 35,
//     "name": "Python (3.5.3)",
//     "is_archived": true
//   },
//   {
//     "id": 34,
//     "name": "Python (3.6.0)",
//     "is_archived": true
//   },
//   {
//     "id": 71,
//     "name": "Python (3.8.1)",
//     "is_archived": false
//   },
//   {
//     "id": 41,
//     "name": "Ruby (2.1.9)",
//     "is_archived": true
//   },
//   {
//     "id": 40,
//     "name": "Ruby (2.2.6)",
//     "is_archived": true
//   },
//   {
//     "id": 39,
//     "name": "Ruby (2.3.3)",
//     "is_archived": true
//   },
//   {
//     "id": 38,
//     "name": "Ruby (2.4.0)",
//     "is_archived": true
//   },
//   {
//     "id": 72,
//     "name": "Ruby (2.7.0)",
//     "is_archived": false
//   },
//   {
//     "id": 42,
//     "name": "Rust (1.20.0)",
//     "is_archived": true
//   },
//   {
//     "id": 73,
//     "name": "Rust (1.40.0)",
//     "is_archived": false
//   },
//   {
//     "id": 74,
//     "name": "TypeScript (3.7.4)",
//     "is_archived": false
//   }
